import React from 'react';
import styled from 'styled-components';
import Container from './Container';
import SectionWrapper from './SectionWrapper';

const ContentWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
`;

const StepsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 4.8rem 0 0;
`;

const Step = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (${({ theme }) => theme.respondTo.tablet}) {
    max-width: 27rem;
    margin: 0 3rem;
  }

  img {
    width: auto;
    height: 7rem;

    @media (${({ theme }) => theme.respondTo.tablet}) {
      width: 9rem;
      height: auto;
    }
  }

  p {
    width: 100%;
    margin: 2.4rem 0 0;

    @media (${({ theme }) => theme.respondTo.mobile}) {
      font-size: 1.4rem;
    }
  }
`;

const Intro = ({ content }) => {
  return (
    <SectionWrapper>
      <Container>
        <ContentWrapper>
          <h2>{content.stepsTitle}</h2>
          <StepsWrapper>
            {content.steps.map(step => (
              <Step key={step.title}>
                <img
                  alt={step.title}
                  src={`${process.env.PUBLIC_URL}/content/images/${step.image}`}
                />
                <p>{step.title}</p>
              </Step>
            ))}
          </StepsWrapper>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Intro;
