import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';
import Field from './Field';
import Button from './Button';
import { store } from '../store/store';
import { totalPrice, formatPrice, cleanCartItems } from '../utils/helpers';

const StyledForm = styled.form`
  margin: 5rem 0 0;
  width: 100%;
`;

const Form = () => {
  const { state, dispatch } = useContext(store);
  const [cart, setCart] = useState(state.cart);
  const [errors, setErrors] = useState(null);

  const handleSubmit = e => {
    e.preventDefault();

    const { name, email, tel, address, comments } = e.target;
    const price = totalPrice(cart, cart.donated);

    const cartItems = cleanCartItems(cart.products);
    let products = [];
    cartItems.map(
      product =>
        (products = [...products, `${product.count}× ${product.title}`])
    );

    const data = {
      name: name.value,
      email: email.value,
      tel: tel.value,
      address: address.value,
      comments: comments.value,
      price: formatPrice(price),
      donation: formatPrice(cart.donated),
      products,
    };

    emailjs
      .send('gmail', 'template_y44U7nBZ', data, 'user_udDUa0jclunYkkVqkZUao')
      .then(
        result => {
          dispatch({ type: 'FORM_SENT_SUCCESFULLY' });
        },
        error => {
          setErrors(error.text);
        }
      );
  };

  return (
    <StyledForm onSubmit={e => handleSubmit(e)}>
      <Field>
        <input required type="text" name="name" />
        <label htmlFor="name">Naam</label>
      </Field>
      <Field>
        <input required type="email" name="email" />
        <label htmlFor="email">E-mail</label>
      </Field>
      <Field>
        <input required type="tel" name="tel" />
        <label htmlFor="tel">Telefoon</label>
      </Field>
      <Field>
        <input required type="text" name="address" />
        <label htmlFor="address">Bezorgadres</label>
      </Field>
      <Field big>
        <textarea maxLength="100" rows="5" name="comments" />
        <label htmlFor="comments">Opmerkingen / persoonlijke boodschap</label>
      </Field>
      <Button big type="submit">
        Versturen
      </Button>
      {errors && <p style={{ color: 'red', marginTop: '1rem' }}>{errors}</p>}
    </StyledForm>
  );
};

export default Form;
