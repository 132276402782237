import React, { useContext } from 'react';
import Modal from './Modal';
import { store } from '../store/store';
import Form from './Form';

const CheckoutModal = () => {
  const { state } = useContext(store);
  return (
    <Modal trigger={state.showCheckout}>
      {!state.formSentSuccesfully ? (
        <>
          <h2>Bestelling afronden</h2>
          <p>
            Vandaag besteld, morgen in huis! Gratis en contactloos in Assen.
          </p>
          <Form />
        </>
      ) : (
        <>
          <h2>Succesvol verzonden!</h2>
          <p>
            Bedankt voor je bestelling! We nemen zo snel mogelijk contact op
            voor de verdere afhandeling van de bestelling. Daarnaast ontvang je
            van ons een betaalverzoek.
          </p>
        </>
      )}
    </Modal>
  );
};

export default CheckoutModal;
