import React from 'react';
import styled from 'styled-components';
import Container from './Container';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin: 12rem 0 7.5rem;

  &:after {
    position: absolute;
    content: '';
    top: -15rem;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    background-image: linear-gradient(-225deg, #ffffff00 0%, #ececec 100%);

    @media (${({ theme }) => theme.respondTo.tablet}) {
      top: -10rem;
      transform: skewY(-12deg) scale(1.4);
    }
  }

  @media (${({ theme }) => theme.respondTo.tablet}) {
    margin: 0 0 calc(12rem - 4.8rem + 10rem);
    padding: 0;
    height: 50rem;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h1`
  margin: 0 0 1rem;
  color: ${({ theme, primaryColor }) =>
    primaryColor ? theme.primaryColor : 'inherit'};
  font-size: ${({ small }) => (small ? '2rem' : '3.5rem')};

  @media (${({ theme }) => theme.respondTo.tablet}) {
    font-size: ${({ small }) => (small ? '2.5rem' : '4rem')};
  }
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 1;
  height: 100%;
  font-size: 2rem;

  @media (${({ theme }) => theme.respondTo.tablet}) {
    margin-right: 7.6rem;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 20rem;

  @media (${({ theme }) => theme.respondTo.mobile}) {
    margin: 3.8rem -1.6rem 0;
    width: calc(100% + 3.2rem);
  }

  @media (${({ theme }) => theme.respondTo.tablet}) {
    max-width: 45%;
    height: 35rem;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (${({ theme }) => theme.respondTo.tablet}) {
    &:after {
      content: '';
      position: absolute;
      background: ${({ theme }) => theme.primaryColor};
      width: 100%;
      height: 100%;
      z-index: -1;
      left: -1.25rem;
      bottom: -1.25rem;
    }
  }
`;

const Hero = ({ content }) => {
  return (
    <Wrapper>
      <Container>
        <ContentWrapper>
          <Content>
            <Title small>{content.company}</Title>
            <Title as="h2" primaryColor>
              {content.title}
            </Title>
            <p>{content.subtitle}</p>
          </Content>
          <ImageWrapper>
            <img
              alt={content.company}
              src={`${process.env.PUBLIC_URL}/content/images/${content.image}`}
            />
          </ImageWrapper>
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};

export default Hero;
