import React, { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${({ big }) => (big ? '10rem' : '5rem')};
  width: 100%;
  margin: 0 0 1rem;

  input,
  textarea {
    border: 1px solid grey;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    font-size: 1.8rem;
    line-height: inherit;
    font-family: inherit;
    padding: ${({ hasValue }) =>
      hasValue ? '1.5rem 0 0 1rem' : '1.5rem 1rem'};
    transition: all 0.25s;
    outline: none;
  }

  textarea {
    padding: ${({ hasValue }) => (hasValue ? '2rem 0 0 1rem' : '1.5rem 1rem')};
  }

  label {
    position: absolute;
    color: grey;
    top: 1.5rem;
    left: 1rem;
    will-change: transform;
    transition: all 0.25s;
    pointer-events: none;

    ${({ theme, hasValue }) =>
      hasValue &&
      `
        transform: translateY(-70%);
        font-size: 1.1rem;
        left: 1.1rem;
        color: ${theme.primaryColor}
      `};
  }
`;

const Field = ({ big, children }) => {
  const [hasValue, setHasValue] = useState(false);

  const handleChange = value => {
    value.length > 0 ? setHasValue(true) : setHasValue(false);
  };

  return (
    <Wrapper
      big={big}
      hasValue={hasValue}
      onChange={e => handleChange(e.target.value)}
    >
      {children}
    </Wrapper>
  );
};

export default Field;
