import styled from 'styled-components';
import { darken } from 'polished';

const Button = styled.button`
  appearance: none;
  box-shadow: none;
  border: none;
  border-radius: 3px;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  background: ${({ theme }) => theme.buyButtonColor};
  color: white;
  cursor: pointer;
  width: ${({ big }) => (big ? '100%' : 'auto')};
  height: ${({ big }) => (big ? '4.5rem' : 'auto')};

  &:hover {
    background: ${({ theme }) => darken('0.05', theme.buyButtonColor)};
  }
`;

export default Button;
