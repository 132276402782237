import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { store } from '../store/store';

const Wrapper = styled.div`
  margin: 2rem 0;
  font-size: 1.6rem;
`;

const Form = styled.form`
  display: flex;
  margin: 1rem 0 0;
`;

const Field = styled.div`
  margin-right: 0.5rem;

  input {
    display: none;
    &:checked + label {
      background: ${({ theme }) => theme.green};
      color: white;
      border-color: ${({ theme }) => theme.green};
    }
  }

  label {
    display: block;
    padding: 0.75rem 1.5rem;
    border: 1px solid darkgray;
    background: white;
    border-radius: 3px;
    cursor: pointer;
  }
`;

const Donation = () => {
  const { state, dispatch } = useContext(store);
  const handleChange = e => {
    dispatch({ type: 'UPDATE_DONATED_AMOUNT', data: Number(e.target.value) });
  };

  return (
    <Wrapper>
      <p>Bijdrage voor de bezorging</p>
      <Form name="donation" onChange={handleChange}>
        <Field>
          <input
            type="radio"
            defaultChecked={state.cart.donated === 0}
            name="donation"
            value="0"
            id="0"
          />
          <label htmlFor="0">€0,00</label>
        </Field>
        <Field>
          <input
            type="radio"
            defaultChecked={state.cart.donated === 150}
            name="donation"
            value="150"
            id="150"
          />
          <label htmlFor="150">€1,50</label>
        </Field>
        <Field>
          <input
            type="radio"
            defaultChecked={state.cart.donated === 300}
            name="donation"
            value="300"
            id="300"
          />
          <label htmlFor="300">€3,00</label>
        </Field>
      </Form>
    </Wrapper>
  );
};

export default Donation;
