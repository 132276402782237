import React from 'react';
import styled from 'styled-components';
import Container from './Container';

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.img`
  @media (${({ theme }) => theme.respondTo.mobile}) {
    max-width: 6rem;
  }
`;
const Wrapper = styled.header`
  position: absolute;
  top: 7rem;
  left: 0;
  width: 100%;
  z-index: 1;
`;

const Header = () => {
  return (
    <>
      <Wrapper>
        <StyledContainer>
          <Logo
            src={`${process.env.PUBLIC_URL}/content/images/logo.svg`}
            alt=""
          />
          <a href="tel:0592355332">
            <b>Vragen?</b> 0592 355 332
          </a>
        </StyledContainer>
      </Wrapper>
    </>
  );
};

export default Header;
