import React from "react";
import styled from "styled-components";
import shortid from "shortid";
import Product from "./Product";

const Wrapper = styled.div`
  flex: 1;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-gap: 2rem;
`;

const Products = ({ products }) => {
  return (
    <Wrapper>
      <Grid>
        {products.map(product => (
          <Product product={product} key={shortid.generate()} />
        ))}
      </Grid>
    </Wrapper>
  );
};

export default Products;
