export const totalPrice = (cart, donated) => {
  const productsTotal = cart.products.reduce(
    (total, product) => total + Number(product.price),
    0
  );
  return productsTotal + donated;
};

export const formatPrice = price =>
  (price / 100).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' });

export const cleanCartItems = products => {
  let cartItems = [];
  products.map(product => {
    const duplicate = products.filter(item => item === product);
    cartItems = [...cartItems, { ...duplicate[0], count: duplicate.length }];
  });

  cartItems = cartItems.filter(
    (v, i, a) =>
      a.findIndex(t => t.title + t.description === v.title + v.description) ===
      i
  );

  return cartItems;
};
