const theme = {
  // Colours
  primaryColor: "#5B181F",
  grey: "#f5f5f5",
  green: "#00b900",
  buyButtonColor: "#00b900",

  // Fonts
  fontSize: "1.8rem",
  fontFamily:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  fontFamilyHeading:
    '"BerlingskeSerif Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',

  // Sizing
  maxContainerWidth: "1400px",

  // transition
  transition: "cubic-bezier(0.165, 0.84, 0.44, 1)",

  // Breakpoints
  respondTo: {
    desktopExtraBig: `min-width: 1600px`,
    desktopSuperBig: `min-width: 1400px`,
    desktopBig: `min-width: 1280px`,
    desktop: `min-width: 1024px`,
    tablet: `min-width: 768px`,
    mobile: `max-width: 480px`
  }
};

export default theme;
