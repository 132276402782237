import styled from 'styled-components';

const SectionWrapper = styled.section`
  width: 100%;
  margin: 0 0 7.5rem;

  @media (${({ theme }) => theme.respondTo.tablet}) {
    margin: 0 0 10rem;
  }
`;

export default SectionWrapper;
