import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'beautiful-react-hooks';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ active }) =>
    active ? 'rgba(0, 0, 0, 0.6)' : 'transparent'};
  pointer-events: ${({ active }) => (active ? 'all' : 'none')};
  will-change: background;
  transition: all 0.25s;
  z-index: 1;
`;

const Background = ({ state, dispatch }) => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  const handleClick = () => {
    dispatch({ type: 'TOGGLE_MODAL_IS_OPEN' });
    isMobile &&
      state.showMobileCart &&
      dispatch({ type: 'TOGGLE_MOBILE_CART' });

    state.showCheckout && dispatch({ type: 'TOGGLE_CHECKOUT' });
  };

  return <Wrapper onClick={() => handleClick()} active={state.modalIsOpen} />;
};

export default Background;
