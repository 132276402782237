import React, { createContext, useReducer } from 'react';

const initialState = {
  cart: {
    products: [],
    donated: 0,
  },
  showCheckout: false,
  showMobileCart: false,
  formSentSuccesfully: false,
  modalIsOpen: false,
};

const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'REMOVE_FROM_CART':
        return {
          ...state,
          cart: {
            ...state.cart,
            products: state.cart.products.filter(
              product => product.title !== action.data.title
            ),
          },
        };
      case 'ADD_TO_CART':
        return {
          ...state,
          cart: {
            ...state.cart,
            products: [...state.cart.products, action.data],
          },
        };
      case 'TOGGLE_CHECKOUT':
        return {
          ...state,
          showCheckout: !state.showCheckout,
        };
      case 'TOGGLE_MODAL_IS_OPEN':
        return {
          ...state,
          modalIsOpen: !state.modalIsOpen,
        };
      case 'TOGGLE_MOBILE_CART':
        return {
          ...state,
          showMobileCart: !state.showMobileCart,
        };
      case 'FORM_SENT_SUCCESFULLY':
        return {
          ...state,
          formSentSuccesfully: true,
        };
      case 'UPDATE_DONATED_AMOUNT':
        return {
          ...state,
          cart: {
            ...state.cart,
            donated: Number(action.data),
          },
        };
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
