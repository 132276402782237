import React from 'react';
import { ThemeProvider } from 'styled-components';
import { useMediaQuery } from 'beautiful-react-hooks';
import GlobalStyle from '../styling/GlobalStyle';
import theme from '../styling/theme';
import CheckoutModal from './CheckoutModal';
import ActionBar from './ActionBar';
import CartModal from './CartModal';

const Layout = ({ children }) => {
  const isMobile = useMediaQuery('(max-width: 1024px)');
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <ActionBar />
        <main>{children}</main>
        <CheckoutModal />
        {isMobile && <CartModal />}
      </>
    </ThemeProvider>
  );
};

export default Layout;
