import React from 'react';
import styled from 'styled-components';
import Container from './Container';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 4rem;
  background: #059604;
  width: 100%;
  color: white;
  font-size: 1.6rem;

  @media (${({ theme }) => theme.respondTo.mobile}) {
    font-size: 1.4rem;
    white-space: nowrap;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
`;

const ActionBar = () => {
  return (
    <Wrapper>
      <Container>
        <p>
          Bestel je boven de € 25? Dan ontvang je van ons gratis een hartje met
          2 bonbons{' '}
          <span role="img" aria-label="heart">
            ❤️
          </span>
        </p>
      </Container>
    </Wrapper>
  );
};

export default ActionBar;
