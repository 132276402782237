import React, { useContext } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import Modal from './Modal';
import { store } from '../store/store';
import Cart from './Cart';
import shopping from '../static/shopping.svg';

const Button = styled.button`
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  appearance: none;
  background: white;
  border: none;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  box-shadow: 0 20px 20px 0 #0000001f;
  z-index: 1;

  svg {
    width: 2.5rem;
    height: auto;
  }
`;

const Count = styled.span`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -0.5rem;
  right: -0.5rem;
  width: 2rem;
  height: 2rem;
  background: black;
  color: white;
  z-index: 1;
  border-radius: 50%;
  font-size: 1.25rem;
`;

const CartModal = () => {
  const { state, dispatch } = useContext(store);

  const handleClick = () => {
    dispatch({ type: 'TOGGLE_MOBILE_CART' });
    dispatch({ type: 'TOGGLE_MODAL_IS_OPEN' });
  };

  return (
    <>
      <Button onClick={() => handleClick()}>
        <Count>{state.cart.products.length}</Count>
        <SVG src={shopping} />
      </Button>
      <Modal trigger={state.showMobileCart}>
        <Cart />
      </Modal>
    </>
  );
};

export default CartModal;
