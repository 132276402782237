import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTransition, animated } from 'react-spring';
import { store } from '../store/store';
import Background from './Background';

const Inner = styled.div`
  padding: 2.4rem 1.6rem;
  background: white;
  width: 100%;
  pointer-events: all;
  border-radius: 4px 4px 0 0;
  overflow: scroll;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (${({ theme }) => theme.respondTo.tablet}) {
    max-width: 50rem;
    padding: 4rem;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0 20px 20px 0 #0000001f;
  }
`;

const Wrapper = styled(animated.div)`
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  pointer-events: none;

  @media (${({ theme }) => theme.respondTo.mobile}) {
    max-height: 75vh;
  }

  @media (${({ theme }) => theme.respondTo.tablet}) {
    bottom: auto;
    top: 50%;
  }
`;

const Modal = ({ children, trigger }) => {
  const { state, dispatch } = useContext(store);
  const isDesktop = window.matchMedia('(min-width: 768px)').matches;

  const transitions = useTransition(trigger, null, {
    from: {
      opacity: isDesktop ? 0 : 1,
      transform: `translateY(${isDesktop ? '-20%' : '100%'})`,
    },
    enter: {
      opacity: 1,
      transform: `translateY(${isDesktop ? '-50%' : '0'})`,
    },
    leave: {
      opacity: isDesktop ? 0 : 1,
      transform: `translateY(${isDesktop ? '-20%' : '100%'})`,
    },
  });

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <Wrapper key={key} style={props}>
              <Inner>{children}</Inner>
            </Wrapper>
          )
      )}
      <Background state={state} dispatch={dispatch} />
    </>
  );
};

export default Modal;
