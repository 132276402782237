import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import Intro from '../components/Intro';
import Shop from '../components/Shop';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Hero from '../components/Hero';

const IntroWrapper = styled.div`
  overflow: hidden;
`;

const Home = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/content/content.json`)
      .then(res => res.json())
      .then(data => setContent(data));
  }, []);

  if (!content) {
    return <p>Loading...</p>;
  }

  return (
    <Layout>
      <IntroWrapper>
        <Header />
        <Hero content={content} />
        <Intro content={content} />
      </IntroWrapper>
      <Shop content={content} products={content.products} />
      <Footer content={content} />
    </Layout>
  );
};

export default Home;
