import React, { useContext } from "react";
import styled from "styled-components";
import { store } from "../store/store";
import Button from "./Button";
import { formatPrice } from "../utils/helpers";

const Wrapper = styled.div`
  background: ${({ theme }) => theme.grey};
  border-radius: 4px;
  overflow: hidden;

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
`;

const Image = styled.div`
  position: relative;
  height: 20rem;
  width: 100%;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: transform 1s ${({ theme }) => theme.transition};
  }
`;

const Content = styled.div`
  padding: 1.6rem;
`;

const Title = styled.h3`
  font-size: 2rem;
`;

const Description = styled.p`
  font-size: 1.6rem;
  margin: 1rem 0;
`;

const Price = styled.p`
  display: inline-block;
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  border-radius: 3px;
  background: white;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
`;

const Product = ({ product }) => {
  const { dispatch } = useContext(store);

  const handleClick = (type, data) => {
    dispatch({ type, data });
  };

  return (
    <Wrapper>
      <Image>
        <img
          src={`${process.env.PUBLIC_URL}/content/images/${product.image}`}
          alt=""
        />
        <Price>{formatPrice(product.price)}</Price>
      </Image>
      <Content>
        <Title>{product.title}</Title>
        <Description>{product.description}</Description>
        <Button onClick={() => handleClick("ADD_TO_CART", product)}>
          Toevoegen
        </Button>
        {/* <button onClick={() => handleClick("REMOVE_FROM_CART", product)}>
          Delete
        </button> */}
      </Content>
    </Wrapper>
  );
};

export default Product;
