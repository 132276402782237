import React, { useContext, useState, useEffect } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import styled from 'styled-components';
import shortid from 'shortid';
import { store } from '../store/store';
import { totalPrice, formatPrice, cleanCartItems } from '../utils/helpers';
import Button from './Button';
import Donation from './Donation';

const Wrapper = styled.aside`
  width: 100%;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    padding: 4.2rem 2.4rem 4.8rem;
    width: 30rem;
    height: 100%;
    position: sticky;
    top: 10rem;
    margin-right: 5rem;
    border-top: 5px solid ${({ theme }) => theme.primaryColor};
    background: linear-gradient(
        45deg,
        transparent 2vw,
        ${({ theme }) => theme.grey} 2vw
      ),
      linear-gradient(-45deg, transparent 2vw, ${({ theme }) => theme.grey} 2vw),
      linear-gradient(45deg, transparent 1.8vw, #e8e8e8 1.8vw),
      linear-gradient(-45deg, transparent 1.8vw, white 1.8vw);
    background-size: 1vw calc(100% + 1.5vw);
  }
`;

const Title = styled.h2`
  margin: 0 0 3rem;

  @media (${({ theme }) => theme.respondTo.desktop}) {
    font-size: 3rem;
  }
`;

const List = styled.ul`
  li {
    border-bottom: 1px solid #e6e6e6;
    padding: 1rem 1.6rem;
    margin: 0 -1.6rem;

    @media (${({ theme }) => theme.respondTo.desktop}) {
      padding: 1rem 2.4rem;
      margin: 0 -2.4rem;
    }
  }
`;

const TotalPrice = styled.p`
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 1.6rem;
  margin: 3rem 0 2rem;
`;

const Cart = () => {
  const { state, dispatch } = useContext(store);
  const [cartItems, setCartItems] = useState(state.cart.products);
  const [total, setTotal] = useState(0);
  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    setCartItems(cleanCartItems(state.cart.products));
    setTotal(totalPrice(state.cart, state.cart.donated));
  }, [state.cart]);

  const handleClick = () => {
    dispatch({ type: 'TOGGLE_CHECKOUT' });
    if (isMobile) {
      state.showMobileCart && dispatch({ type: 'TOGGLE_MOBILE_CART' });
    } else {
      dispatch({ type: 'TOGGLE_MODAL_IS_OPEN' });
    }
  };

  return (
    <Wrapper>
      <Title>Mijn bestelling</Title>
      {cartItems.length === 0 && <p>Je winkelmandje is nog leeg.. </p>}
      {cartItems.length > 0 && (
        <List>
          {cartItems.map(product => (
            <li key={shortid.generate()}>
              {product.count}× {product.title}
            </li>
          ))}
        </List>
      )}
      {total > 0 && (
        <>
          <Donation />
          <TotalPrice>
            <span>
              <b>Totaal:</b>
            </span>
            <b>{formatPrice(total)}</b>
          </TotalPrice>
          <Button big={isMobile} onClick={() => handleClick()}>
            Bestellen
          </Button>
        </>
      )}
    </Wrapper>
  );
};

export default Cart;
