import React from 'react';
import styled from 'styled-components';
import Container from './Container';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.primaryColor};
  color: white;
  padding: 1.25rem 0;

  @media (${({ theme }) => theme.respondTo.mobile}) {
    padding: 1.5rem 0;
  }
`;
const StyledContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (${({ theme }) => theme.respondTo.mobile}) {
    a {
      margin-top: 0.5rem;
    }
  }
`;

const Footer = ({ content }) => {
  const year = new Date().getFullYear();

  return (
    <Wrapper>
      <StyledContainer>
        <p>
          {content.company} — {year}
        </p>
        <a href={`mailto:${content.email}`}>{content.email}</a>
      </StyledContainer>
    </Wrapper>
  );
};

export default Footer;
